import React from "react";
import { Link } from "gatsby";
import instagram from "../img/social/instagram.svg";
import logo from "../img/logo.svg";
import NavbarStyles from "./Navbar.module.scss";

const Navbar = class extends React.Component {
  render() {
    return (
      <div className={NavbarStyles.wrapper}>
        <nav
          className={NavbarStyles.navWrapper}
          role="navigation"
          aria-label="main-navigation"
        >
          <Link to="/" title="Logo">
            <img
              src={logo}
              alt="Pizza for Breakfast"
              className={NavbarStyles.logo}
            />
          </Link>
          <div className={NavbarStyles.menuItemWrapper}>
            <Link
              className={NavbarStyles.menuItem}
              activeClassName={NavbarStyles.menuItemActive}
              partiallyActive={true}
              style={{ width: "165px" }}
              to="/comics/geekingout"
            >
              JEEZ, WHAT A GEEK
            </Link>
            <Link
              className={NavbarStyles.menuItem}
              activeClassName={NavbarStyles.menuItemActive}
              partiallyActive={true}
              style={{ width: "155px" }}
              to="/comics/watchlist"
            >
              Watchlist
            </Link>
            <Link
              className={NavbarStyles.menuItem}
              activeClassName={NavbarStyles.menuItemActive}
              partiallyActive={true}
              style={{ width: "230px" }}
              to="/comics/theonewiththestories"
            >
              The one with the stories
            </Link>
          </div>
          <a
            className={NavbarStyles.icon}
            title="instagram"
            href="https://instagram.com/justpizzaforbreakfast"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagram}
              alt="Instagram"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
        </nav>
      </div>
    );
  }
};

export default Navbar;
