import React from "react";
import { Link } from "gatsby";

import FooterStyles from "./Footer.module.scss";
import patronLogo from "../img/become_a_patron_button@2x.png";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className={FooterStyles.wrapper}>
        <div className={FooterStyles.innerWrapper}>
          <div className={FooterStyles.menuWrapper}>
            <Link className={FooterStyles.menuItem} to="/">
              Home
            </Link>
            <Link className={FooterStyles.menuItem} to="/comics/geekingout">
              Jeez, what a geek
            </Link>
            <Link className={FooterStyles.menuItem} to="/comics/watchlist">
              Watchlist
            </Link>
            <Link
              className={FooterStyles.menuItem}
              to="/comics/theonewiththestories"
            >
              The one with the stories
            </Link>
            <a
              className={FooterStyles.menuItem}
              title="instagram"
              href="https://instagram.com/justpizzaforbreakfast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </div>
        </div>
        <div className={FooterStyles.innerWrapper}>
          <img
            src={patronLogo}
            width={179}
            height={42}
            className={FooterStyles.patronLogo}
          />
        </div>
        <div className={FooterStyles.innerWrapper}>
          <span className={FooterStyles.copyright}>
            All artwork and content on this site is copyright ©{" "}
            {new Date().getFullYear()}. Please don't steal.
          </span>
        </div>
      </footer>
    );
  }
};

export default Footer;
